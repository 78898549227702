<template>
    <div>
        <el-form :model="AddDateModel" ref="AddDateRef" :rules="AddDateRules" label-width="130px">
            <el-form-item label="人员名称：" prop="departmentUserName">
                <el-select v-model="AddDateModel.departmentUserName" filterable placeholder="请选择人员名称" clearable @change="usernameValueChange(AddDateModel.departmentUserName)">
                    <el-option v-for="item in useroptions" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="选择指标：" prop="specialRewardId" v-if="tit=='专项奖励填报'">
                <el-select v-model="AddDateModel.specialRewardId" placeholder="请选择指标" clearable @change="speValueChange(AddDateModel.specialRewardId)">
                    <el-option v-for="item in speoptions" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>

            <!-- 单项补助及奖惩填报指标id -->
            <el-form-item label="选择指标：" prop="individualSubsidyId" v-if="tit=='单项补助及奖惩填报'">
                <el-select v-model="AddDateModel.individualSubsidyId" placeholder="请选择指标" clearable @change="indiValueChange(AddDateModel.individualSubsidyId)">
                    <el-option v-for="item in vidoptions" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>

            <!-- 个人绩效考核指标管理列表 -->
            <el-form-item label="选择指标：" prop="personalPerformanceId" v-if="tit=='个人绩效考核指标填报'">
                <el-select v-model="AddDateModel.personalPerformanceId" placeholder="请选择指标" clearable @change="perValueChange(AddDateModel.personalPerformanceId)">
                    <el-option v-for="item in peroptions" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>

            <!-- 科研绩效指标填报  -->
            <el-form-item label="选择指标：" prop="scientificResearchId" v-if="tit=='科研绩效指标填报'">
                <el-select v-model="AddDateModel.scientificResearchId" placeholder="请选择指标" clearable @change="scrValueChange(AddDateModel.scientificResearchId)">
                    <el-option v-for="item in scroptions" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>

            <!-- 工作量填报 -->
            <el-form-item label="选择指标：" prop="workloadId" v-if="tit=='工作量填报'">
                <el-select v-model="AddDateModel.workloadId" placeholder="请选择指标" clearable @change="worValueChange(AddDateModel.workloadId)">
                    <el-option v-for="item in workoptions" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>

            <!-- 证明材料 -->
            <el-form-item label="证明材料：" prop="fileId">
                <el-upload class="avatar-uploader" ref="otherLicense" action :auto-upload="false" :on-preview="handlePicPreview" :on-remove="handleRemove" :file-list="AddDateModel.fileId" :limit="imgLimit" :on-change="otherSectionFile" list-type="picture-card" multiple>
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible" append-to-body>
                    <img width="100%" :src="dialogImageUrl" class="avatar" />
                </el-dialog>
            </el-form-item>

            <el-form-item label="选择审核状态：" prop="auditStatus">
                <el-select v-model="AddDateModel.auditStatus" placeholder="请选择审核状态" clearable @change="audiValueChange(AddDateModel.auditStatus)">
                    <el-option v-for="item in auditStatusOptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="备注：" prop="remark">
                <el-input type="textarea" v-model="AddDateModel.remark"></el-input>
            </el-form-item>

            <!-- 审核人 -->
            <el-form-item label="审核人：" prop="auditUserId">
                <el-input v-model="AddDateModel.auditUserId" placeholder="请填写审核人" clearable></el-input>
            </el-form-item>

            <!-- 填报时间 -->
            <el-form-item label="填报时间：" prop="fillingTime">
                <el-date-picker v-model="AddDateModel.fillingTime" type="date" value-format="yyyy-MM-dd" placeholder="选择填报时间">
                </el-date-picker>
            </el-form-item>

            <!-- 审核时间 -->
            <el-form-item label="审核时间：" prop="auditTime">
                <el-date-picker v-model="AddDateModel.auditTime" type="date" value-format="yyyy-MM-dd" placeholder="选择审核时间">
                </el-date-picker>
            </el-form-item>

            <div class="btnCen">
                <el-button v-if="addDepClick" type="primary" :loading="addDepClickKing" @click="addKeshi">{{diaBtn}}</el-button>
                 <el-button v-else type="info" :loading="addDepClickKing"></el-button>
            </div>

        </el-form>
    </div>
</template>

<script>
import _qs from "qs";
export default {
    props: ["diaBtn", "EditDateModel", "wardId", "tit"],
    data() {
        // 业务结束证明材料
        let validateImage = (rule, value, callback) => {
            // console.log(this.AddDateModel.fileId);
            //验证器
            if (this.AddDateModel.fileId.length <= 0) {
                //为true代表图片在  false报错
                callback(new Error("请上传业务结束证明材料"));
            } else {
                callback();
            }
        };
        return {
            // 审核状态（0审核通过  1审核未通过  2待审核  3撤销审核）
            auditStatusOptions: [
                {
                    value: "0",
                    label: "审核通过",
                },
                {
                    value: "1",
                    label: "审核未通过",
                },
                {
                    value: "2",
                    label: "待审核",
                },
                {
                    value: "3",
                    label: "撤销审核",
                },
            ],
            speoptions: [],
            vidoptions: [],
            peroptions: [],
            scroptions: [],
            workoptions: [],
            useroptions: [],
            dialogVisible: false,
            dialogImageUrl: "",
            value1: "",
            value: true,
            addDepClick: true,
            addDepClickKing: false,
            AddDateModel: {
                departmentUserName: "",
                specialRewardId: "",
                individualSubsidyId: "",
                personalPerformanceId: "",
                scientificResearchId: "",
                workloadId: "",
                auditStatus: "",
                fillingTime: "",
                auditTime: "",
                auditUserId: "",
                remark: "",
                fileId: [],
            },
            imgLimit: 9, //最多可上传几张图片
            dialogVisible: false,
            dialogImageUrl: "",
            fileCoList: [], //图片回显
            AddDateRules: {
                departmentUserName: [
                    {
                        required: true,
                        message: "请输入人员名称",
                        trigger: "change",
                    },
                ],
                specialRewardId: [
                    {
                        required: true,
                        message: "请选择专项奖励指标",
                        trigger: "change",
                    },
                ],
                individualSubsidyId: [
                    {
                        required: true,
                        message: "请选择单项补助及奖惩填报指标",
                        trigger: "change",
                    },
                ],
                personalPerformanceId: [
                    {
                        required: true,
                        message: "请选择个人绩效考核指标",
                        trigger: "change",
                    },
                ],
                scientificResearchId: [
                    {
                        required: true,
                        message: "请选择科研绩效指标",
                        trigger: "change",
                    },
                ],
                workloadId: [
                    {
                        required: true,
                        message: "请选择工作量指标",
                        trigger: "change",
                    },
                ],
                auditStatus: [
                    {
                        required: true,
                        message: "请选择审核状态",
                        trigger: "change",
                    },
                ],
                fillingTime: [
                    {
                        required: true,
                        message: "请选择填报时间",
                        trigger: "change",
                    },
                ],
                auditTime: [
                    {
                        required: true,
                        message: "请选择审核时间",
                        trigger: "change",
                    },
                ],
                auditUserId: [
                    {
                        required: true,
                        message: "请填写审核人",
                        trigger: "blur",
                    },
                ],
                fileId: [
                    {
                        required: true,
                        validator: validateImage,
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    watch: {
        EditDateModel: {
            handler(newVal, oldVal) {
                this.echo();
            },
            deep: true,
        },
        // 业务结束证明材料
        fileCoList: {
            deep: true,
            handler() {
                if (this.fileCoList) {
                    this.AddDateModel.fileId = this.fileCoList;
                }
            },
        },
    },
    created() {
        if (this.tit == "专项奖励填报") {
            this.rewardList();
        } else if (this.tit == "单项补助及奖惩填报") {
            this.vidbsiList();
        } else if (this.tit == "个人绩效考核指标填报") {
            this.perforList();
        } else if (this.tit == "科研绩效指标填报") {
            this.scresList();
        } else if (this.tit == "工作量填报") {
            this.listworkIn();
        }

        this.hosfindBy();
        this.echo();
    },
    methods: {
        echo() {
            if (this.EditDateModel.id) {
                this.AddDateModel.departmentUserId =
                    this.EditDateModel.departmentUserId;
                this.AddDateModel.departmentUserName =
                    this.EditDateModel.departmentUserName;
                this.AddDateModel.specialRewardId =
                    this.EditDateModel.specialRewardId;
                this.AddDateModel.auditStatus =
                    this.EditDateModel.auditStatus + "";
                this.AddDateModel.fillingTime = this.EditDateModel.fillingTime;
                this.AddDateModel.auditTime = this.EditDateModel.auditTime;
                this.AddDateModel.auditUserId = this.EditDateModel.auditUserId;
                this.AddDateModel.remark = this.EditDateModel.remark;
                this.AddDateModel.individualSubsidyId =
                    this.EditDateModel.individualSubsidyId;
                this.AddDateModel.personalPerformanceId =
                    this.EditDateModel.personalPerformanceId;
                this.AddDateModel.scientificResearchId =
                    this.EditDateModel.scientificResearchId;
                this.AddDateModel.workloadId = this.EditDateModel.workloadId;
            }
        },
        async getFile(ids) {
            let idsdata = _qs.stringify({
                ids: ids,
            });
            let res = await this.$axios.sysgetFile(idsdata);
            //   console.log(res.data.data);
            let result = res.data.data;
            result.map((item) => {
                // console.log(item)
                item.url = this.GLOBAL + item.url;
                item.pictureId = item.id;
            });
            // console.log(result)
            this.fileCoList = result;
        },
        async rewardList() {
            let data = {
                deleteFlag: "0", //数据状态（0正常 1已删除）
            };
            let { data: res } = await this.$axios.hosSpFind(data);
            //   console.log(res)
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.speoptions = res.rows;
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        async vidbsiList() {
            let data = {
                deleteFlag: "0", //数据状态（0正常 1已删除）
            };
            let { data: res } = await this.$axios.hosIndFind(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.vidoptions = res.rows;
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        async perforList() {
            let data = {
                deleteFlag: "0", //数据状态（0正常 1已删除）
            };
            let { data: res } = await this.$axios.hosperFind(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.peroptions = res.rows;
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        async scresList() {
            let data = {
                deleteFlag: "0", //数据状态（0正常 1已删除）
            };
            let { data: res } = await this.$axios.hosScFind(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.scroptions = res.rows;
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        async listworkIn() {
            let data = {
                deleteFlag: "0", //数据状态（0正常 1已删除）
            };
            let { data: res } = await this.$axios.hosFind(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.workoptions = res.rows;
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        async hosfindBy() {
            let { data: res } = await this.$axios.hosfindBy();
            //   console.log(res)
            if (res.code == 401) {
                this.$router.push("/login");
            } else {
                this.useroptions = res;
            }
        },
        handlePicPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        // 业务申请材料
        handleRemove(file, fileList) {
            this.AddDateModel.fileId.map((item, index) => {
                if (item.uid == file.uid) {
                    this.AddDateModel.fileId.splice(index, 1);
                }
            });
        },
        otherSectionFile(file) {
            const typeArr = [
                "image/png",
                "image/gif",
                "image/jpeg",
                "image/jpg",
            ];
            const isJPG = typeArr.indexOf(file.raw.type) !== -1;
            const isLt20M = file.size / 1024 / 1024 < 20;
            if (!isJPG) {
                this.$message.error("只能是图片!");
                this.$refs.upload.clearFiles();
                this.otherFiles = null;
                return;
            }
            if (!isLt20M) {
                this.$message.error("上传图片大小不能超过 20MB!");
                this.$refs.upload.clearFiles();
                this.otherFiles = null;
                return;
            }
            this.otherFiles = file.raw;
            // FormData 对象
            var formData = new FormData();
            // 文件对象
            formData.append("file", this.otherFiles);
            formData.append("filePath", "keshijixiao");
            let config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                methods: "post",
            };
            this.$axios.hosUpload(formData, config).then((res) => {
                // console.log(file)
                let result = res.data.data;
                let arr = [];
                for (let i = 0; i < result.length; i++) {
                    //   console.log(result[i])
                    arr.push(result[i].id);
                }
                file.pictureId = arr.join(",");
                this.AddDateModel.fileId.push(file);
                // console.log(this.AddDateModel.fileId);
            });
        },
        audiValueChange(val) {
            // console.log(val)
            this.AddDateModel.auditStatus = val;
        },
        speValueChange(val) {
            // console.log(val);
            this.AddDateModel.specialRewardId = val;
        },
        indiValueChange(val) {
            // console.log(val);
            this.AddDateModel.individualSubsidyId = val;
        },
        perValueChange(val) {
            this.AddDateModel.personalPerformanceId = val;
        },
        scrValueChange(val) {
            this.AddDateModel.scientificResearchId = val;
        },
        worValueChange(val) {
            this.AddDateModel.workloadId = val;
        },
        usernameValueChange(val) {
            this.AddDateModel.departmentUserId = val;

            let obj = {};
            obj = this.useroptions.find((item) => {
                return item.id === val;
            });
            this.AddDateModel.departmentUserName = obj.name;
        },
        clearFill() {
            this.$refs.AddDateRef.resetFields();
            this.AddDateModel = {
                departmentUserName: "",
                specialRewardId: "",
                auditStatus: "",
                fillingTime: "",
                auditTime: "",
                auditUserId: "",
                remark: "",
                fileId: [],
            };
            this.fileCoList = "";
            this.$emit("hosList");
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        addKeshi() {
            if (this.diaBtn == "新增") {
                // 新增
                this.addhosDepartmentUser();
            } else if (this.diaBtn == "修改") {
                // 修改
                this.hosupdate();
            }
        },
        addhosDepartmentUser() {
            let newFilelist;
            let newArr = [];
            if (this.AddDateModel.fileId) {
                newFilelist = this.AddDateModel.fileId;
                for (let i = 0; i < newFilelist.length; i++) {
                    newArr.push(newFilelist[i].pictureId);
                }
            }
            let quesNewArr = newArr.join(",");

            this.$refs.AddDateRef.validate(async (valid) => {
                if (valid) {
                    this.addDepClick = false;
                    this.addDepClickKing = true;
                    let data;
                    if (this.tit == "专项奖励填报") {
                        data = _qs.stringify({
                            departmentUserId:
                                this.AddDateModel.departmentUserId, //人员id
                            departmentUserName:
                                this.AddDateModel.departmentUserName, //人员名称
                            specialRewardId: this.AddDateModel.specialRewardId, //专项奖励指标id
                            fileId: quesNewArr, //证明材料id（多个文件的id用逗号分隔）
                            auditStatus: this.AddDateModel.auditStatus, //审核状态（0审核通过  1审核未通过  2待审核  3撤销审核）
                            remark: this.AddDateModel.remark, //备注说明
                            // auditUserId:this.AddDateModel.auditUserId,//审核人id
                            auditUserId: "2", //审核人id
                            fillingTime: this.AddDateModel.fillingTime, //填报时间
                            auditTime: this.AddDateModel.auditTime, //审核时间
                        });
                    } else if (this.tit == "单项补助及奖惩填报") {
                        data = _qs.stringify({
                            departmentUserId:
                                this.AddDateModel.departmentUserId, //人员id
                            departmentUserName:
                                this.AddDateModel.departmentUserName, //人员名称
                            individualSubsidyId:
                                this.AddDateModel.individualSubsidyId, //单项补助及奖惩填报指标id
                            fileId: quesNewArr, //证明材料id（多个文件的id用逗号分隔）
                            auditStatus: this.AddDateModel.auditStatus, //审核状态（0审核通过  1审核未通过  2待审核  3撤销审核）
                            remark: this.AddDateModel.remark, //备注说明
                            // auditUserId:this.AddDateModel.auditUserId,//审核人id
                            auditUserId: "2", //审核人id
                            fillingTime: this.AddDateModel.fillingTime, //填报时间
                            auditTime: this.AddDateModel.auditTime, //审核时间
                        });
                    } else if (this.tit == "个人绩效考核指标填报") {
                        data = _qs.stringify({
                            departmentUserId:
                                this.AddDateModel.departmentUserId, //人员id
                            departmentUserName:
                                this.AddDateModel.departmentUserName, //人员名称
                            personalPerformanceId:
                                this.AddDateModel.personalPerformanceId, //个人绩效考核指标填报id
                            fileId: quesNewArr, //证明材料id（多个文件的id用逗号分隔）
                            auditStatus: this.AddDateModel.auditStatus, //审核状态（0审核通过  1审核未通过  2待审核  3撤销审核）
                            remark: this.AddDateModel.remark, //备注说明
                            // auditUserId:this.AddDateModel.auditUserId,//审核人id
                            auditUserId: "2", //审核人id
                            fillingTime: this.AddDateModel.fillingTime, //填报时间
                            auditTime: this.AddDateModel.auditTime, //审核时间
                        });
                    } else if (this.tit == "科研绩效指标填报") {
                        data = _qs.stringify({
                            departmentUserId:
                                this.AddDateModel.departmentUserId, //人员id
                            departmentUserName:
                                this.AddDateModel.departmentUserName, //人员名称
                            scientificResearchId:
                                this.AddDateModel.scientificResearchId, //个人绩效考核指标填报id
                            fileId: quesNewArr, //证明材料id（多个文件的id用逗号分隔）
                            auditStatus: this.AddDateModel.auditStatus, //审核状态（0审核通过  1审核未通过  2待审核  3撤销审核）
                            remark: this.AddDateModel.remark, //备注说明
                            // auditUserId:this.AddDateModel.auditUserId,//审核人id
                            auditUserId: "2", //审核人id
                            fillingTime: this.AddDateModel.fillingTime, //填报时间
                            auditTime: this.AddDateModel.auditTime, //审核时间
                        });
                    } else if (this.tit == "工作量填报") {
                        data = _qs.stringify({
                            departmentUserId:
                                this.AddDateModel.departmentUserId, //人员id
                            departmentUserName:
                                this.AddDateModel.departmentUserName, //人员名称
                            workloadId: this.AddDateModel.workloadId, //个人绩效考核指标填报id
                            fileId: quesNewArr, //证明材料id（多个文件的id用逗号分隔）
                            auditStatus: this.AddDateModel.auditStatus, //审核状态（0审核通过  1审核未通过  2待审核  3撤销审核）
                            remark: this.AddDateModel.remark, //备注说明
                            // auditUserId:this.AddDateModel.auditUserId,//审核人id
                            auditUserId: "2", //审核人id
                            fillingTime: this.AddDateModel.fillingTime, //填报时间
                            auditTime: this.AddDateModel.auditTime, //审核时间
                        });
                    }

                    let res;
                    if (this.tit == "专项奖励填报") {
                        res = await this.$axios.hosAdd(data);
                    } else if (this.tit == "单项补助及奖惩填报") {
                        res = await this.$axios.subadd(data);
                    } else if (this.tit == "个人绩效考核指标填报") {
                        res = await this.$axios.hospeAdd(data);
                    } else if (this.tit == "科研绩效指标填报") {
                        res = await this.$axios.resAdd(data);
                    } else if (this.tit == "工作量填报") {
                        res = await this.$axios.worAdd(data);
                    }

                    //   console.log(res);
                    this.addDepClick = true;
                    this.addDepClickKing = false;
                    if (res.data.code == 401) {
                        this.$router.push("/login");
                    } else if (res.status == 200) {
                        this.$emit("hosList");
                        this.clearFill();
                    } else {
                        this.$message({
                            message: res.msg,
                            type: "error",
                        });
                    }
                }
            });
        },
        hosupdate() {
            let newFilelist;
            let newArr = [];
            if (this.AddDateModel.fileId) {
                newFilelist = this.AddDateModel.fileId;
                for (let i = 0; i < newFilelist.length; i++) {
                    newArr.push(newFilelist[i].pictureId);
                }
            }
            let quesNewArr = newArr.join(",");

            this.$refs.AddDateRef.validate(async (valid) => {
                if (!valid) return;
                this.addDepClick = false;
                this.addDepClickKing = true;
                let data;
                if (this.tit == "专项奖励填报") {
                    data = _qs.stringify({
                        id: this.wardId, //专项填报id
                        departmentUserId: this.AddDateModel.departmentUserId, //人员id
                        departmentUserName:
                            this.AddDateModel.departmentUserName, //人员名称
                        specialRewardId: this.AddDateModel.specialRewardId, //专项奖励指标id
                        fileId: quesNewArr, //证明材料id（多个文件的id用逗号分隔）
                        auditStatus: this.AddDateModel.auditStatus, //审核状态（0审核通过  1审核未通过  2待审核  3撤销审核）
                        remark: this.AddDateModel.remark, //备注说明
                        // auditUserId:this.AddDateModel.auditUserId,//审核人id
                        auditUserId: "2", //审核人id
                        fillingTime: this.AddDateModel.fillingTime, //填报时间
                        auditTime: this.AddDateModel.auditTime, //审核时间
                    });
                } else if (this.tit == "单项补助及奖惩填报") {
                    data = _qs.stringify({
                        id: this.wardId, //专项填报id
                        departmentUserId: this.AddDateModel.departmentUserId, //人员id
                        departmentUserName:
                            this.AddDateModel.departmentUserName, //人员名称
                        individualSubsidyId:
                            this.AddDateModel.individualSubsidyId, //单项补助奖励指标id
                        fileId: quesNewArr, //证明材料id（多个文件的id用逗号分隔）
                        auditStatus: this.AddDateModel.auditStatus, //审核状态（0审核通过  1审核未通过  2待审核  3撤销审核）
                        remark: this.AddDateModel.remark, //备注说明
                        // auditUserId:this.AddDateModel.auditUserId,//审核人id
                        auditUserId: "2", //审核人id
                        fillingTime: this.AddDateModel.fillingTime, //填报时间
                        auditTime: this.AddDateModel.auditTime, //审核时间
                    });
                } else if (this.tit == "个人绩效考核指标填报") {
                    data = _qs.stringify({
                        id: this.wardId, //专项填报id
                        departmentUserId: this.AddDateModel.departmentUserId, //人员id
                        departmentUserName:
                            this.AddDateModel.departmentUserName, //人员名称
                        personalPerformanceId:
                            this.AddDateModel.personalPerformanceId, //单项补助奖励指标id
                        fileId: quesNewArr, //证明材料id（多个文件的id用逗号分隔）
                        auditStatus: this.AddDateModel.auditStatus, //审核状态（0审核通过  1审核未通过  2待审核  3撤销审核）
                        remark: this.AddDateModel.remark, //备注说明
                        // auditUserId:this.AddDateModel.auditUserId,//审核人id
                        auditUserId: "2", //审核人id
                        fillingTime: this.AddDateModel.fillingTime, //填报时间
                        auditTime: this.AddDateModel.auditTime, //审核时间
                    });
                } else if (this.tit == "科研绩效指标填报") {
                    data = _qs.stringify({
                        id: this.wardId, //专项填报id
                        departmentUserId: this.AddDateModel.departmentUserId, //人员id
                        departmentUserName:
                            this.AddDateModel.departmentUserName, //人员名称
                        scientificResearchId:
                            this.AddDateModel.scientificResearchId, //单项补助奖励指标id
                        fileId: quesNewArr, //证明材料id（多个文件的id用逗号分隔）
                        auditStatus: this.AddDateModel.auditStatus, //审核状态（0审核通过  1审核未通过  2待审核  3撤销审核）
                        remark: this.AddDateModel.remark, //备注说明
                        // auditUserId:this.AddDateModel.auditUserId,//审核人id
                        auditUserId: "2", //审核人id
                        fillingTime: this.AddDateModel.fillingTime, //填报时间
                        auditTime: this.AddDateModel.auditTime, //审核时间
                    });
                } else if (this.tit == "工作量填报") {
                    data = _qs.stringify({
                        id: this.wardId, //专项填报id
                        departmentUserId: this.AddDateModel.departmentUserId, //人员id
                        departmentUserName:
                            this.AddDateModel.departmentUserName, //人员名称
                        workloadId: this.AddDateModel.workloadId, //单项补助奖励指标id
                        fileId: quesNewArr, //证明材料id（多个文件的id用逗号分隔）
                        auditStatus: this.AddDateModel.auditStatus, //审核状态（0审核通过  1审核未通过  2待审核  3撤销审核）
                        remark: this.AddDateModel.remark, //备注说明
                        // auditUserId:this.AddDateModel.auditUserId,//审核人id
                        auditUserId: "2", //审核人id
                        fillingTime: this.AddDateModel.fillingTime, //填报时间
                        auditTime: this.AddDateModel.auditTime, //审核时间
                    });
                }

                let res;
                if (this.tit == "专项奖励填报") {
                    res = await this.$axios.hosupdate(data);
                } else if (this.tit == "单项补助及奖惩填报") {
                    res = await this.$axios.subupdate(data);
                } else if (this.tit == "个人绩效考核指标填报") {
                    res = await this.$axios.hospeUpdate(data);
                } else if (this.tit == "科研绩效指标填报") {
                    res = await this.$axios.resUpdate(data);
                } else if (this.tit == "工作量填报") {
                    res = await this.$axios.worUpdate(data);
                }

                // console.log(res);
                this.addDepClick = true;
                this.addDepClickKing = false;
                if (res.data.code == 401) {
                    this.$router.push("/login");
                } else if (res.data.code == 200) {
                    this.clearFill();
                    this.$emit("hosList");
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: "error",
                    });
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.btnCen {
    text-align: center;
}
</style>
